*{
    margin: 0;
    padding: 0;
}
#container{
    background-color: aliceblue;
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 25px 6px -23px rgba(0,0,0,0.45);

}

#navCompanyLogo{  
    position: absolute;
    left: 1rem;
    
   
}
/* #navCompanyLogo img{
    
    width: 6.5rem;
    margin-top: 20px;
    margin-left: 3.5rem;
    object-fit: contain;
} */
#navtop_search {
margin-top: 0.7rem;
min-height: 2.25rem;
border-radius: 0.7rem;
}


#notifications{
    
   
    display: flex;
    flex-direction: row;
    max-width: fit-content;
   
}



    /* Remove or modify transition properties for Mantine Modal overlay */
.mantine-Overlay-root {
    
        display: none !important;
        margin-top: 4rem;
        
       
  }
/* logout model */
  .mantine-Modal-inner {
    /* Your custom styles for the modal inner container */
    /* For example: */
   
    position: fixed;
    left: 38rem;
    top: 2.7rem;
  }
  /* logout */
.mantine-144ktnu {
    flex: 0 0 7.75rem;
}
  .mantine-1pdibkf {
    padding-left: 0rem;
    padding-right: 1rem;
    max-width: 100%;
    margin-left: 5rem;
    margin-right: 0;
}
/* navbar top container */
.mantine-13fogqh {
    
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 4.75rem;
    max-height: 4.75rem;
    position: fixed;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 0.0625rem solid #e9ecef;
    margin: 0;
    padding: 0.625rem;
    padding-right: 0rem;
    padding-left: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
}
/* burger component */
.mantine-1eq5ywr {
    position: absolute;
    top:3rem;
    left: 0rem;
    
}
/* berger button */
.mantine-1eq5ywr {
     padding-left: 10px;
    padding-right: 10px; 
    
    
}
.mantine-1eq5ywr:hover {
    background: lightgrey;
    border-radius: 50%;
    

}



  
  
