/* The device with borders */
.smartphone {
    width: 200px;
    height: 424px;
    position: absolute;
    top: 45%;
    left: 25%;
    border: 16px black solid;
    border-top-width: 40px;
    border-bottom-width: 40px;
    border-radius: 36px;
    z-index: 15;
    }
    
    /* The horizontal line on the top of the device */
    .smartphone:before {
      content: '';
      display: block;
      width: 60px;
      height: 5px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #333;
      border-radius: 10px;
    }
    
    /* The circle on the bottom of the device */
    .smartphone:after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      bottom: -50px;
      transform: translate(-50%, -50%);
      background: #333;
      border-radius: 50%;
    }
    
    /* The screen (or content) of the device */
    /* .smartphone .content {
      width: 360px;
      height: 640px;
      background: white;
    } */
  
    /* laptop */
    .laptop {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: scale(.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
      transform: scale(.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
      left: 54%;
      top:55%;
      position: absolute;
      width: 1200px;
      height: 700px;
      border-radius: 6px;
      border-style: solid;
      border-color: black;
      border-width: 24px 24px 80px;
      z-index: 10;
      /* background-color: black; */
    
    }q
    
    /* The keyboard of the laptop */
    /* .laptop:after {
      content: '';
      display: block;
      position: absolute;
      width: 1600px;
      height: 60px;
      margin: 780px 0 0 -110px;
      background: black;
      border-radius: 6px;
    } */
    
    /* The top of the keyboard */
    .laptop:before {
      content: '';
      display: block;
      position: absolute;
      width: 250px;
      height: 30px;
      bottom: -110px;
      left: 50%;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      background: #f1f1f1;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      z-index: 1;
    }
    
    /* The screen (or content) of the device */
    .laptop .content {
      /* width: 1366px; */
      /* height: 800px; */
      /* overflow: hidden; */
      /* border: none; */
    }