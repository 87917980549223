@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700;900&display=swap");


:root{
    --color-bg: #F9F9F9;
    --color-transparent: transparent;
    --color-onclick: #2070C1;
    --color-text:#777777;
    --color-bold-text:#373737;
    --color-white:#FFFFFF;
    --color-icon: #777777;
    --color-on:#43a047;
    --color-online:#1b5e20;
    --color-off:#e53935;
    --color-offline:#b71c1c;
    --transition: all 400ms ease;
     --box-shadow:   0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                    0 12.5px 10px rgba(0, 0, 0, 0.06),
                    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                    0 100px 80px rgba(0, 0, 0, 0.12); 

}
/* html{
    overflow: hidden;
   
} */
body{
    font-family: 'Roboto',sans-serif;
}
.active{
    color: var(--color-onclick);
}
#dashboardlink{
    text-decoration: none;
    color: var(--color-text);
}
#dashboardlink:hover{
 text-decoration: underline;
 color: #2070C1;
}

